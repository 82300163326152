// extracted by mini-css-extract-plugin
export var caseContactFormSection = "b0_k3";
export var caseStudyBackground__lineColor = "b0_k0";
export var caseStudyHead__imageWrapper = "b0_kX";
export var caseStudyProjectsSection = "b0_k4";
export var caseStudyQuote__bgRing = "b0_kZ";
export var caseStudySolution__ring = "b0_k8";
export var caseStudySolution__ringThree = "b0_l5";
export var caseStudySolution__ringTwo = "b0_k9";
export var caseStudyVideo__ring = "b0_k2";
export var caseStudy__bgDark = "b0_kV";
export var caseStudy__bgLight = "b0_kT";
export var caseStudy__bgLightGray = "b0_ml";